import artWeek1 from '../assets/images/pastEvents/artWeek2024/1.JPG'
import artWeek2 from '../assets/images/pastEvents/artWeek2024/2.JPG'
import artWeek3 from '../assets/images/pastEvents/artWeek2024/3.jpg'
import artWeek4 from '../assets/images/pastEvents/artWeek2024/4.jpg'
import artWeek5 from '../assets/images/pastEvents/artWeek2024/5.jpg'
import artWeek6 from '../assets/images/pastEvents/artWeek2024/6.jpg'
import artWeek7 from '../assets/images/pastEvents/artWeek2024/7.jpg'

import borisBrecha1 from '../assets/images/pastEvents/borisBrecha/1.jpg'
import borisBrecha2 from '../assets/images/pastEvents/borisBrecha/2.jpg'
import borisBrecha3 from '../assets/images/pastEvents/borisBrecha/3.jpg'
import borisBrecha4 from '../assets/images/pastEvents/borisBrecha/4.jpg'

import camelphat1 from '../assets/images/pastEvents/Camelphat/1.jpg'
import camelphat2 from '../assets/images/pastEvents/Camelphat/2.jpg'
import camelphat3 from '../assets/images/pastEvents/Camelphat/3.jpg'
import camelphat4 from '../assets/images/pastEvents/Camelphat/4.jpg'
import camelphat5 from '../assets/images/pastEvents/Camelphat/5.jpg'
import camelphat6 from '../assets/images/pastEvents/Camelphat/6.jpg'

import hernanCattaneo1 from '../assets/images/pastEvents/hernanCattaneo/1.png'
import hernanCattaneo2 from '../assets/images/pastEvents/hernanCattaneo/2.png'

import julianJewel1 from '../assets/images/pastEvents/JulianJeweil/1.jpg'
import julianJewel2 from '../assets/images/pastEvents/JulianJeweil/2.jpg'
import julianJewel3 from '../assets/images/pastEvents/JulianJeweil/3.jpg'
import julianJewel4 from '../assets/images/pastEvents/JulianJeweil/4.jpg'

import roger1 from '../assets/images/pastEvents/rogerSanchez/1.jpg'
import roger2 from '../assets/images/pastEvents/rogerSanchez/2.jpg'
import roger3 from '../assets/images/pastEvents/rogerSanchez/3.jpg'
import roger4 from '../assets/images/pastEvents/rogerSanchez/4.jpg'
import roger5 from '../assets/images/pastEvents/rogerSanchez/5.jpg'
import roger6 from '../assets/images/pastEvents/rogerSanchez/6.jpg'
import roger7 from '../assets/images/pastEvents/rogerSanchez/7.jpg'
import roger8 from '../assets/images/pastEvents/rogerSanchez/8.jpg'
import roger9 from '../assets/images/pastEvents/rogerSanchez/9.jpg'

import strangeHuman1 from '../assets/images/pastEvents/StrageHuman/1.jpg'
import strangeHuman2 from '../assets/images/pastEvents/StrageHuman/2.jpg'
import strangeHuman3 from '../assets/images/pastEvents/StrageHuman/3.jpg'
import strangeHuman4 from '../assets/images/pastEvents/StrageHuman/4.jpg'
import strangeHuman5 from '../assets/images/pastEvents/StrageHuman/5.jpg'

import yeriMua1 from '../assets/images/pastEvents/yeriMua/1.JPG'
import yeriMua2 from '../assets/images/pastEvents/yeriMua/2.JPG'

import accept1 from '../assets/images/pastEvents/Accept/1.jpg'
import accept2 from '../assets/images/pastEvents/Accept/2.jpg'
import accept3 from '../assets/images/pastEvents/Accept/3.jpg'
import accept4 from '../assets/images/pastEvents/Accept/4.jpg'
import accept5 from '../assets/images/pastEvents/Accept/5.jpg'

import nicoMoreno1 from '../assets/images/pastEvents/NicoMoreno/1.jpg'
import nicoMoreno2 from '../assets/images/pastEvents/NicoMoreno/2.jpg'
import nicoMoreno3 from '../assets/images/pastEvents/NicoMoreno/3.jpg'
import nicoMoreno4 from '../assets/images/pastEvents/NicoMoreno/4.jpg'

import rhapsodyOfFire1 from '../assets/images/pastEvents/RhapsodyOfFire/1.jpg'
import rhapsodyOfFire2 from '../assets/images/pastEvents/RhapsodyOfFire/2.jpg'
import rhapsodyOfFire3 from '../assets/images/pastEvents/RhapsodyOfFire/3.jpg'
import rhapsodyOfFire4 from '../assets/images/pastEvents/RhapsodyOfFire/4.jpg'
import rhapsodyOfFire5 from '../assets/images/pastEvents/RhapsodyOfFire/5.jpg'

import hammerFall1 from '../assets/images/pastEvents/HammerFall/1.jpg'
import hammerFall2 from '../assets/images/pastEvents/HammerFall/2.jpg'
import hammerFall3 from '../assets/images/pastEvents/HammerFall/3.jpg'
import hammerFall4 from '../assets/images/pastEvents/HammerFall/4.jpg'
import hammerFall5 from '../assets/images/pastEvents/HammerFall/5.jpg'
import hammerFall6 from '../assets/images/pastEvents/HammerFall/6.jpg'
import hammerFall7 from '../assets/images/pastEvents/HammerFall/7.jpg'

import mathame1 from '../assets/images/pastEvents/Mathame/1.jpg'
import mathame2 from '../assets/images/pastEvents/Mathame/2.jpg'
import mathame3 from '../assets/images/pastEvents/Mathame/3.jpg'
import mathame4 from '../assets/images/pastEvents/Mathame/4.jpg'

import miuraBoxing1 from '../assets/images/pastEvents/MiuraBoxing/1.JPG'
import miuraBoxing2 from '../assets/images/pastEvents/MiuraBoxing/2.JPG'
import miuraBoxing3 from '../assets/images/pastEvents/MiuraBoxing/3.JPG'
import miuraBoxing4 from '../assets/images/pastEvents/MiuraBoxing/4.JPG'
import miuraBoxing5 from '../assets/images/pastEvents/MiuraBoxing/5.JPG'

import savageAndShe1 from '../assets/images/pastEvents/SavageAndShe/1.png'
import savageAndShe2 from '../assets/images/pastEvents/SavageAndShe/2.png'
import savageAndShe3 from '../assets/images/pastEvents/SavageAndShe/3.png'

import nueve1 from '../assets/images/pastEvents/9x9/DSC01.jpg'
import nueve2 from '../assets/images/pastEvents/9x9/DSC02.jpg'
import nueve3 from '../assets/images/pastEvents/9x9/DSC03.jpg'
import nueve4 from '../assets/images/pastEvents/9x9/DSC04.jpg'
import nueve5 from '../assets/images/pastEvents/9x9/DSC05.jpg'
import nueve6 from '../assets/images/pastEvents/9x9/DSC06.jpg'
import nueve7 from '../assets/images/pastEvents/9x9/DSC07.jpg'
import nueve8 from '../assets/images/pastEvents/9x9/DSC08.jpg'
import nueve9 from '../assets/images/pastEvents/9x9/DSC09.jpg'
import nueve10 from '../assets/images/pastEvents/9x9/DSC010.jpg'

import egs1 from '../assets/images/pastEvents/El_Gran_Silencio/DSC07992.jpg'
import egs2 from '../assets/images/pastEvents/El_Gran_Silencio/DSC08000.jpg'
import egs3 from '../assets/images/pastEvents/El_Gran_Silencio/DSC08020.jpg'
import egs4 from '../assets/images/pastEvents/El_Gran_Silencio/DSC08061.jpg'

import skanp1 from '../assets/images/pastEvents/Ska_N_ Punk/DSC07865.jpg'
import skanp2 from '../assets/images/pastEvents/Ska_N_ Punk/DSC07900.jpg'
import skanp3 from '../assets/images/pastEvents/Ska_N_ Punk/DSC08017.jpg'
import skanp4 from '../assets/images/pastEvents/Ska_N_ Punk/DSC08124.jpg'

import sv1 from '../assets/images/pastEvents/Salon_Victoria/DSC07902.jpg'
import sv2 from '../assets/images/pastEvents/Salon_Victoria/DSC07916.jpg'
import sv3 from '../assets/images/pastEvents/Salon_Victoria/DSC07918.jpg'
import sv4 from '../assets/images/pastEvents/Salon_Victoria/DSC07926.jpg'

import inpg1 from '../assets/images/pastEvents/Indira_Paganotto/Portada.jpeg'
import inpg2 from '../assets/images/pastEvents/Indira_Paganotto/Indira 02.jpeg'
import inpg3 from '../assets/images/pastEvents/Indira_Paganotto/Indira 03.jpeg'
import inpg4 from '../assets/images/pastEvents/Indira_Paganotto/Indira 04.jpeg'
import inpg5 from '../assets/images/pastEvents/Indira_Paganotto/Indira 05.jpeg'
import inpg6 from '../assets/images/pastEvents/Indira_Paganotto/Indira 06.jpeg'

import paulO1 from '../assets/images/pastEvents/Paul_O/1.jpg'
import paulO2 from '../assets/images/pastEvents/Paul_O/2.jpg'
import paulO3 from '../assets/images/pastEvents/Paul_O/3.jpg'
import paulO4 from '../assets/images/pastEvents/Paul_O/4.jpg'
import paulO5 from '../assets/images/pastEvents/Paul_O/5.jpg'
import paulO6 from '../assets/images/pastEvents/Paul_O/6.jpg'
import paulO7 from '../assets/images/pastEvents/Paul_O/7.jpg'
import paulO8 from '../assets/images/pastEvents/Paul_O/8.jpg'
import paulO9 from '../assets/images/pastEvents/Paul_O/9.jpg'
import paulO10 from '../assets/images/pastEvents/Paul_O/10.jpg'
import paulO11 from '../assets/images/pastEvents/Paul_O/11.jpg'
import paulO12 from '../assets/images/pastEvents/Paul_O/12.jpg'
import paulO13 from '../assets/images/pastEvents/Paul_O/13.jpg'
import paulO14 from '../assets/images/pastEvents/Paul_O/14.jpg'
import paulO15 from '../assets/images/pastEvents/Paul_O/15.jpg'
import paulO16 from '../assets/images/pastEvents/Paul_O/16.jpg'
import paulO17 from '../assets/images/pastEvents/Paul_O/17.jpg'
import paulO18 from '../assets/images/pastEvents/Paul_O/18.jpg'
import paulO19 from '../assets/images/pastEvents/Paul_O/19.jpg'
import paulO20 from '../assets/images/pastEvents/Paul_O/20.jpg'
import paulO21 from '../assets/images/pastEvents/Paul_O/21.jpg'
import paulO22 from '../assets/images/pastEvents/Paul_O/22.jpg'
import paulO23 from '../assets/images/pastEvents/Paul_O/23.jpg'
import paulO24 from '../assets/images/pastEvents/Paul_O/24.jpg'
import paulO25 from '../assets/images/pastEvents/Paul_O/25.jpg'
import paulO26 from '../assets/images/pastEvents/Paul_O/26.jpg'
import paulO27 from '../assets/images/pastEvents/Paul_O/27.jpg'
import paulO28 from '../assets/images/pastEvents/Paul_O/28.jpg'
import paulO29 from '../assets/images/pastEvents/Paul_O/29.jpg'

import sasha1 from '../assets/images/pastEvents/Sasha/1.jpg'
import sasha2 from '../assets/images/pastEvents/Sasha/2.jpg'
import sasha3 from '../assets/images/pastEvents/Sasha/3.jpg'
import sasha4 from '../assets/images/pastEvents/Sasha/4.jpg'
import sasha5 from '../assets/images/pastEvents/Sasha/5.jpg'
import sasha6 from '../assets/images/pastEvents/Sasha/6.jpg'
import sasha7 from '../assets/images/pastEvents/Sasha/7.jpg'
import sasha8 from '../assets/images/pastEvents/Sasha/8.jpg'
import sasha9 from '../assets/images/pastEvents/Sasha/9.jpg'
import sasha10 from '../assets/images/pastEvents/Sasha/10.jpg'
import sasha11 from '../assets/images/pastEvents/Sasha/11.jpg'
import sasha12 from '../assets/images/pastEvents/Sasha/12.jpg'
import sasha13 from '../assets/images/pastEvents/Sasha/13.jpg'
import sasha14 from '../assets/images/pastEvents/Sasha/14.jpg'
import sasha15 from '../assets/images/pastEvents/Sasha/15.jpg'
import sasha16 from '../assets/images/pastEvents/Sasha/16.jpg'
import sasha17 from '../assets/images/pastEvents/Sasha/17.jpg'
import sasha18 from '../assets/images/pastEvents/Sasha/18.jpg'
import sasha19 from '../assets/images/pastEvents/Sasha/19.jpg'
import sasha20 from '../assets/images/pastEvents/Sasha/20.jpg'
import sasha21 from '../assets/images/pastEvents/Sasha/21.jpg'
import sasha22 from '../assets/images/pastEvents/Sasha/22.jpg'
import sasha23 from '../assets/images/pastEvents/Sasha/23.jpg'
import sasha24 from '../assets/images/pastEvents/Sasha/24.jpg'
import sasha25 from '../assets/images/pastEvents/Sasha/25.jpg'
import sasha26 from '../assets/images/pastEvents/Sasha/26.jpg'
import sasha27 from '../assets/images/pastEvents/Sasha/27.jpg'
import sasha28 from '../assets/images/pastEvents/Sasha/28.jpg'

export const pastEventsImages = [
    {
    event: 'artWeek2024',
        images: [
            artWeek1,
            artWeek2,
            artWeek3,
            artWeek4,
            artWeek5,
            artWeek6,
            artWeek7
        ]
    },
    {
        event: 'borisBrecha',
        images: [
            borisBrecha1,
            borisBrecha2,
            borisBrecha3,
            borisBrecha4
        ]
    },
    {
        event: 'camelphat',
        images: [
            camelphat1,
            camelphat2,
            camelphat3,
            camelphat4,
            camelphat5,
            camelphat6
        ]
    },
    {
        event: 'hernanCattaneo',
        images: [
            hernanCattaneo1,
            hernanCattaneo2,
        ]
    },
    {
        event: 'julianJewel',
        images: [
            julianJewel1,
            julianJewel2,
            julianJewel3,
            julianJewel4
        ]
    },
    {
        event: 'miuraBoxing',
        images: [
            miuraBoxing1,
            miuraBoxing2,
            miuraBoxing3,
            miuraBoxing4,
            miuraBoxing5
        ]
    },
    {
        event: 'roger',
        images: [
            roger1,
            roger2,
            roger3,
            roger4,
            roger5,
            roger6,
            roger7,
            roger8,
            roger9
        ]
    },
    {
        event: 'strangeHuman',
        images: [
            strangeHuman1,
            strangeHuman2,
            strangeHuman3,
            strangeHuman4,
            strangeHuman5
        ]
    },
    {
        event: 'yeriMua',
        images: [
            yeriMua1,
            yeriMua2
        ]
    },
    {
        event: 'Accept',
        images: [
            accept1,
            accept2,
            accept3,
            accept4,
            accept5
        ]
    },
    {
        event: 'NicoMoreno',
        images: [
            nicoMoreno1,
            nicoMoreno2,
            nicoMoreno3,
            nicoMoreno4
        ]
    },
    {
        event: 'RhapsodyOfFire',
        images: [
            rhapsodyOfFire1,
            rhapsodyOfFire2,
            rhapsodyOfFire3,
            rhapsodyOfFire4,
            rhapsodyOfFire5
        ]
    },
    {
        event: 'HammerFall',
        images: [
            hammerFall1,
            hammerFall2,
            hammerFall3,
            hammerFall4,
            hammerFall5,
            hammerFall6,
            hammerFall7
        ]
    },
    {
        event: 'Mathame',
        images: [
            mathame1,
            mathame2,
            mathame3,
            mathame4
        ]
    },
    {
        event: 'SavageAndShe',
        images:[
            savageAndShe1,
            savageAndShe2,
            savageAndShe3
        ]
    },
    {
        event: '99999999',
        images:[
            nueve1,
            nueve2,
            nueve3,
            nueve4,
            nueve5,
            nueve6,
            nueve7,
            nueve8,
            nueve9,
            nueve10
        ]
    },
    {
        event: 'granSilencio',
        images:[
            egs1,
            egs2,
            egs3,
            egs4
        ]
    },
    {
        event: 'indiraPaganotto',
        images:[
            inpg1,
            inpg2,
            inpg3,
            inpg4,
            inpg5,
            inpg6,
        ]
    },
    {
        event: 'paulOak',
        images:[
            paulO1,
            paulO2,
            paulO3,
            paulO4,
            paulO5,
            paulO6,
            paulO7,
            paulO8,
            paulO9,
            paulO10,
            paulO11,
            paulO12,
            paulO13,
            paulO14,
            paulO15,
            paulO16,
            paulO17,
            paulO18,
            paulO19,
            paulO20,
            paulO21,
            paulO22,
            paulO23,
            paulO24,
            paulO25,
            paulO26,
            paulO27,
            paulO28,
            paulO29
        ]
    },
    {
        event: 'salonvictoria',
        images:[
            sv1,
            sv2,
            sv3,
            sv4,
        ]
    },
    {
        event: 'Sasha',
        images:[
            sasha1, 
            sasha2, 
            sasha3, 
            sasha4, 
            sasha5, 
            sasha6, 
            sasha7, 
            sasha8, 
            sasha9, 
            sasha10,
            sasha11,
            sasha12,
            sasha13,
            sasha14,
            sasha15,
            sasha16,
            sasha17,
            sasha18,
            sasha19,
            sasha20,
            sasha21,
            sasha22,
            sasha23,
            sasha24,
            sasha25,
            sasha26,
            sasha27,
            sasha28
        ]
    },
    {
        event: 'skanpunk',
        images:[
            skanp1,
            skanp2,
            skanp3,
            skanp4,
        ]
    }
];
