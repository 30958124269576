import React from 'react'
import './NextEvents.css'
import Slider from '../../Slider/Slider';

import Miura from '../../../assets/images/nextEvents/miura.png'
import Argy from '../../../assets/images/nextEvents/Argy.png'
import versosvivos from '../../../assets/images/nextEvents/Festival-Versos-Vivos.png'
import hc from '../../../assets/images/nextEvents/HC.png'
import Gardenh from '../../../assets/images/nextEvents/Garden-Halloween-rs.png'
import casta from '../../../assets/images/nextEvents/La-Castañeda.png'
import toolroom from '../../../assets/images/nextEvents/Toolroom.png'
import fcespedes from '../../../assets/images/nextEvents/Francisco-Cespedes.png'


const nextEvents = [
  {
    image: Miura,
    title: 'Miura',
    buy: 'https://boletos.miuraboxing.com'
  },
  {
    image:Argy ,
    title: 'Argy / Heik - Johnny Chief - Fakes',
    buy: 'https://www.ticketfairy.com/event/argy-looloo'
  },
  {
    image:versosvivos ,
    title: 'Festival Versos Vivos ',
    buy: 'https://festivalversosvivos.ticketapp.mx '
  },
  {
    image:hc ,
    title: 'Hernan Cattaneo',
    buy: 'https://hc24.boletia.com'
  },  
  {
    image:casta ,
    title: 'La castañeda',
    buy: 'https://boletomovil.com/evento/la-castaneda-2024-12-07'
  },
  {
    image:fcespedes ,
    title: 'Francisco Céspedesy Diana Vanoni',
    buy: 'https://boletomovil.com/evento/francisco-cespedes-y-diana-vanoni-2024-11-29 '
  },
  {
    image:toolroom ,
    title: 'TOOLROOM',
    buy: 'https://gardenclub.com.mx/product/garden-toolroom-preventa/'
  },


];

export default function NextEvents() {
  return (
    <div className='NextEvents'>
      <Slider 
        items={nextEvents}
        handleClick={() => console.log('do nothing')}
      />
    </div>
  )
}
