import React, { useCallback, useState } from 'react'
import './PastEvents.css'
import Dialogs from '../Dialog/Dialogs'

import artWeek2024 from '../../../assets/images/pastEvents/art-week-2024.png'
import borisBrecha from '../../../assets/images/pastEvents/boris-brecha.png'
import hernanCattaneo from '../../../assets/images/pastEvents/hernan-cataneo.png'
import julianJewel from '../../../assets/images/pastEvents/julian-jewel.png'
import strangeHuman from '../../../assets/images/pastEvents/strange-human.png'
import yeriMua from '../../../assets/images/pastEvents/yeri-mua.png'
import roger from '../../../assets/images/pastEvents/rogersanchez.png'
import camelphat from '../../../assets/images/pastEvents/camelphat.png'
import Lightbox from '../../Lightbox/Lightbox'

import Accept from '../../../assets/images/pastEvents/Accept.png'
import HammerFall from '../../../assets/images/pastEvents/HammerFall.png'
import NicoMoreno from '../../../assets/images/pastEvents/NicoMoreno.png'
import RhapsodyOfFire from '../../../assets/images/pastEvents/RhapsodyOfFire.png'

import mathame from '../../../assets/images/pastEvents/Mathame.png'
import miuraBoxing from '../../../assets/images/pastEvents/MiuraBoxing.png'
import savageAndShe from '../../../assets/images/pastEvents/SavageAndShe.png'

import {pastEventsImages} from '../../../data/pastEventsImages'
import SliderPastEvents from '../../SliderPastEvents/SliderPastEvents'
import skanpunk from '../../../assets/images/pastEvents/skanpunk.png'
import salonvictoria from '../../../assets/images/pastEvents/salonvictoria.png'
import granSilencio from '../../../assets/images/pastEvents/granSilencio.png'
import indiraPagannoto from '../../../assets/images/pastEvents/indira.png'
import nuevenueve from '../../../assets/images/pastEvents/nuevenueve.png'
import Sasha from '../../../assets/images/pastEvents/Sasha.png'
import paulOak from '../../../assets/images/pastEvents/paulOak.png'

const pastEventsItems = [
  
  {
    image: artWeek2024,
    title: 'Art Week 2024',
    event: 'artWeek2024'
  },
  {
    image: borisBrecha,
    title: 'Boris brecha',
    event: 'borisBrecha'
  },
  {
    image: camelphat,
    title: 'Camelphat',
    event: 'camelphat'
  },
  {
    image: hernanCattaneo,
    title: 'Hernán Cattáneo',
    event: 'hernanCattaneo'
  },
  {
    image: julianJewel,
    title: 'Julian Jewel',
    event: 'julianJewel'
  },
  {
    image: roger,
    title: 'Roger Sanchez',
    event: 'roger'
  },
  {
    image: strangeHuman,
    title: 'Strange human',
    event: 'strangeHuman'
  },
  {
    image: yeriMua,
    title: 'Yeri Mua',
    event: 'yeriMua'
  },
  {
    image: HammerFall,
    title: 'HammerFall',
    event: 'HammerFall'
  },
  {
    image: RhapsodyOfFire,
    title: 'Rhapsody Of Fire',
    event: 'RhapsodyOfFire'
  },
  {
    image: Accept,
    title: 'Accept',
    event: 'Accept'
  },
  {
    image: NicoMoreno,
    title: 'Nico Moreno',
    event: 'NicoMoreno'
  },

  {
    image: mathame,
    title: 'Mathame',
    event: 'Mathame'
  },
  {
    image: miuraBoxing,
    title: 'Miura Boxing',
    event: 'miuraBoxing'
  },
  {
    image: savageAndShe,
    title: 'Savage & She',
    event: 'SavageAndShe'
  },
  {
    image: skanpunk,
    title: 'Ska n Punk',
    event: 'skanpunk'
  },
  {
    image: salonvictoria,
    title: 'Salon Victoria',
    event: 'salonvictoria'
  },
  {
    image: granSilencio,
    title: 'El gran silencio',
    event: 'granSilencio'
  },
  {
    image: indiraPagannoto,
    title: 'Indira Paganotto',
    event: 'indiraPaganotto'
  },
  {
    image: nuevenueve,
    title: '99999999',
    event: '99999999'
  },
  {
    image: Sasha,
    title: 'Sasha',
    event: 'Sasha'
  },
  {
    image: paulOak,
    title: 'Paul Oakenfold',
    event: 'paulOak'
  },
];

export default function PastEvents() {

  const [showLightbox, setShowLightbox] = useState(false);
  const [startPosition, setStartPosition] = useState(0);

  const [itemsGallery, setItemsGallery] = useState();

  const handleImageClick = useCallback((event) => {
    // console.log('Entró al handleImageClick: ', pastEventsImages);
    // console.log('Entró al handleImageClick: ', event);
    const found = pastEventsImages.find((item) => item.event === event);
    console.log('found: ', found.images);
    if (found) {
      setItemsGallery(found.images);
      setShowLightbox(true);
    }
    // setStartPosition(theIndex);
    
  }, []);

  const handleClickClose = useCallback(() => {
    console.log('Entró al handleClickClose');
    setShowLightbox(false);
  }, []);
  
  return (
    <div className='PastEvents relative'>

      {/* mobile */}
      <div className='block md:hidden'>
        <div className='eventos-title lg:pb-8 lg:pt-32 pt-10'>
          Eventos anteriores
        </div>
        <div className='lg:pt-6'>
          <SliderPastEvents items={pastEventsItems} handleClick={handleImageClick} />
        </div>
      </div>

      {/* tablet y web */}
      <div className='hidden md:block'>
      <div className='eventos-title pb-8 pt-8 lg:pt-32'>
        Eventos anteriores
        </div>
        <div className='grid grid-cols-3  lg:grid-cols-4 gap-0'>
          {
            pastEventsItems.map((item, index) => (
              <div className='relative m-0 p-0 cursor-pointer' key={index} onClick={() => handleImageClick(item.event)}>
                <img src={item.image} alt={item.title} className='w-full h-full object-cover'/>

                <div className="absolute inset-0 flex items-end mb-16 justify-center mx-16">
                    <h2 className="past-title ">{item.title}</h2>
                </div>
              </div>
            ))
          }
        </div>
      </div>

      {
        showLightbox &&
        <div className='absolute top-0 z-50'>
          <Dialogs open={showLightbox} handleClose={handleClickClose} imageItems={itemsGallery} startPosition={startPosition} />
        </div>
      }

      

    </div>
  )
}
